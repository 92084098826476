import React from 'react'
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';

const OnlineDesigner = () => {
    return (
        <div>
            <div id='prodboard-container' className='designer'></div>
            <Helmet>
                <script src={withPrefix('jquery-2.1.1.min.js')} />
                <script src='https://constructor.prodboard-au.com/inject/SR_7682d9d21a394eb0bf32773df10ce6c8/GGVH.js'></script>
            </Helmet>
        </div>
    )
}

export default OnlineDesigner